// custom theme for storybook UI
// see: https://storybook.js.org/docs/vue/configure/theming

import { create } from '@storybook/theming';

export default create({
  base: 'light',
  colorPrimary: '#E31937', // jpl red
  colorSecondary: '#489FDF', // jpl aqua

  // Typography
  fontBase: 'Metropolis, sans-serif',

  // White Labeling
  brandTitle: 'oui',
  brandUrl: 'https://www.obj6.com',
  brandImage: 'logo.svg',
  brandTarget: '_self'
});
